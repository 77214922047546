/* eslint-disable jsx-a11y/control-has-associated-label */

import React from 'react'
import { Link } from 'gatsby'
import SEO from '../components/seo'
import ContactInfo from '../utils/contactInfo'
import { useForm } from '@formcarry/react';

import { FormContain, BasicPageContain, CopyBlockStyle, Grid, GridLeft, GridRight } from '../utils/copperTypography';



 
const FooterForm = () => {
  // Call the `useForm` hook in your function component
  const {state, submit} = useForm({
    id: 'dnGUtvGpjeU0'
  });
 
  // Success message
  if (state.submitted) {
    return <div>Thank you! We received your submission.</div>;
  }
 
  return (
    <form onSubmit={submit}>

      <label htmlFor="contactFormName">Name</label>
      <input type="text" id="contactFormName" name="contact[name]" placeholder="Name"/>

      <label htmlFor="contactFormEmail" >Email</label>
      <input type="email" name="email" placeholder="Email" autoCorrect="off" id="contactFormEmail" autoCapitalize="off" />

      <label htmlFor="contactFormPhone">Phone Number</label>
      <input type="tel" id="contactFormPhone" name="phone" placeholder="Phone Number" />

      <label htmlFor="contactFormOrder">Order Number</label>
      <input type="text" id="contactFormOrder" name="order number" placeholder="Order Number" />

      <label htmlFor="contactFormMessage">Message</label>
      <textarea rows="10" id="contactFormMessage" name="body" placeholder="Message"></textarea>

      <input type="submit" class="btn" value="Send Form" />

    </form>
  );
}


const ContactForm = () => <FormContain><FooterForm /></FormContain>;


const ContactPage = () => (
  <BasicPageContain>
    <SEO title="Copper Compression : Warranty" />
    <CopyBlockStyle>
      <h3>Copper Compression</h3>
      <h1>Support and FAQ</h1>
      <Grid>
        <GridLeft>
          <h3>Contact information</h3>
          <ContactInfo />
        </GridLeft>
        <GridRight>
          <h3>For FASTEST response please fill out the form below if you need assistance. Thank you.</h3>

          <ContactForm />

          <p className="wide">We back all of our products with a money-back guarantee. If you are not completely satisfied with this product for any reason, CopperCompression.com (a Copper Compression, LLC site) will gladly refund your purchase price, excluding shipping and handling charges, within 30 days of purchase.</p>
          <Link to="/" className="mt40">Go back to the homepage</Link>          
        </GridRight>
      </Grid>
    </CopyBlockStyle>
  </BasicPageContain>
)

export default ContactPage
